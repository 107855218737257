import { Link } from 'gatsby'
import React from 'react'
import Image from '~/components/atoms/Image'
import TextLink from '~/components/atoms/TextLink'
import FadeInWhenVisible from '~/components/FadeInWhenVisible'

export default function ArticlePreview({className, uri, title, categories, featuredImage }) {
  return (
    <div className={`${className}`}>
      <Link to={uri} className="block group">
        <FadeInWhenVisible className="overflow-hidden mb-7">
          <div className="transform group-hover:scale-110 transition-transform duration-700">
            <div className="aspect-w-16 aspect-h-12 bg-sand">
              <Image data={featuredImage?.node} className="!absolute w-full h-full" />
            </div>
          </div>
        </FadeInWhenVisible>
        <FadeInWhenVisible transition={{delay: '.05'}}>
          <h6 className="text-h6 text-blue mb-2.5">{categories?.nodes[0]?.name || 'Article'}</h6>
        </FadeInWhenVisible>
        <FadeInWhenVisible transition={{delay: '.1'}}>
          <h5 className="text-h5 mb-6" dangerouslySetInnerHTML={{ __html: title }} />
        </FadeInWhenVisible>
        <FadeInWhenVisible transition={{delay: '.15'}}>
          <TextLink title="Keep reading" />
        </FadeInWhenVisible>
      </Link>
    </div>
  )
}
